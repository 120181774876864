import React from 'react'
import SimpleBanner from '../../Screens/Common/SimpleBanner'
import AboutUs_banner from '../../Assets/about/banarimg.png'
import OurOffers from '../../Components/Offers/OurOffers'
import SubscribeTo from '../../Components/home/SubscribeTo'
import ContactForm from '../../Components/Contact/ContactForm'
const Offers = () => {
  return (
    <div>
      <SimpleBanner
        image={AboutUs_banner}
        name="Offers"
        path="Home >> Offers"
      />
      <OurOffers/>
      <ContactForm />
      <SubscribeTo/>
      
    </div>
  )
}

export default Offers