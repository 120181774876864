import React from "react";
// import image from '../../Assets/footerbg.png'
import {
  FaFacebookF,
  FaInstagram,
  FaPinterestSquare,
  FaYoutubeSquare,
} from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import logo from "../../Assets/logo/logo.svg";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { BsEnvelopeFill } from "react-icons/bs";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <footer
        className="footer-bg bg-cover bg-center"
        style={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="px-4 py-10  bg-[#F0EEE1]">
          <div className="grid md:grid-cols-[1.5fr_2fr_1fr] lg:grid-cols-[1fr_2fr_1fr] grid-cols-1 gap-10 w-[90%] mx-auto">
            <div className="md:ml-11">
              <h2 className="text-lg font-bold mb-4 text-[#164805]">
                QUICK LINKS
              </h2>
              <ul className="text-[#878353]">
                <li
                  onClick={() => {
                    navigate("/");
                    window.scrollTo(600, 0);
                  }}
                  className="mb-2 flex items-center cursor-pointer"
                >
                  Home
                </li>
                <li
                  onClick={() => {
                    navigate("/about");
                    window.scrollTo(600, 0);
                  }}
                  className="mb-2 flex items-center cursor-pointer"
                >
                  About Us
                </li>
                <li
                  onClick={() => {
                    navigate("/services");
                    window.scrollTo(600, 0);
                  }}
                  className="mb-2 flex items-center cursor-pointer"
                >
                  Services
                </li>
                <li
                  onClick={() => {
                    navigate("/offers");
                    window.scrollTo(600, 0);
                  }}
                  className="mb-2 flex items-center cursor-pointer"
                >
                  Offers
                </li>
                <li
                  onClick={() => {
                    navigate("/contact");
                    window.scrollTo(600, 0);
                  }}
                  className="mb-2 flex items-center cursor-pointer"
                >
                  Contact
                </li>
              </ul>
            </div>
            <div className="text-center lg:mr-32 text-[#878353] flex flex-col items-start md:items-center">
              <img src={logo} alt="logo" className="w-24 lg:w-28 md:w-20" />
              <p className="mb-4 text-start md:text-center mt-4">
                Passionate Experts Ready to Serve You: Uniting Skill,
                Dedication, and Innovation for Your Ultimate Satisfaction.
              </p>
              <div className="flex justify-center space-x-4 text-[#0C1669]">
                <div>
                  <a
                    href="https://www.facebook.com/p/Kaira-Salon-61555288714974/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebookF className="text-[#164805]" />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.instagram.com/kaira.salon/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram className="text-[#164805]" />
                  </a>
                </div>
                <FaTwitter className="text-[#164805]" />

                <FaYoutubeSquare className="text-[#164805]" />
                <FaPinterestSquare className="text-[#164805]" />
              </div>
            </div>
            <div>
              <h2 className="text-lg font-bold mb-4 text-[#164805]">
                CONTACT US
              </h2>
              <div className="flex items-center gap-4 mb-4">
                <FaMapMarkerAlt className="text-xl text-[#164805]" />
                <p className="mb-2 text-[#878353]">
                  Sahidnagar market Bulding,c/34, Saheed Nagar, Bhubaneswar,
                  Odisha 751007
                </p>
              </div>
              <div className="flex items-center gap-4 mb-4">
                <BsEnvelopeFill className="text-xl text-[#164805]" />
                <p className="mb-2 text-[#878353]">bangkokspa2017@gmail.com</p>
              </div>
              <div className="flex items-center gap-4 mb-4">
                <FaPhoneAlt className="text-xl text-[#164805]" />
                <p className="mb-2 text-[#878353]">+91-9861688844</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="py-4 bg-[#164805] text-[#F1F1F1]">
        <div className="md:mx-16 px-4 flex flex-wrap justify-between items-center">
          <p className="w-full sm:w-auto text-center sm:text-left">
            © {currentYear} KAYA SPA BHUBANESWAR All Rights Reserved.
          </p>
          <p className="w-full sm:w-auto text-center sm:text-right">
            Design & Developed by{" "}
            <a
              href="https://www.webbocket.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              WEB_BOCKET
            </a>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
