import React, { useEffect, useState } from 'react'
import img1 from '../../Assets/home/Services1.png'
import img2 from '../../Assets/home/Services2.png'
import img3 from '../../Assets/home/Services3.png'
import icon1 from '../../Assets/home/icons.png'
import icon2 from '../../Assets/home/icons1.png'
import icon3 from '../../Assets/home/icons3.png'
import axios from 'axios'
const WhatWereOfferingHome = () => {


    const [services, setServices] = useState([]);
    const getAllServices = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_KEY}/services//getAllServiceByBranchId/6745874a9aa6780fb268869a`,
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                // console.log(response.data);
                setServices(response.data)
            })
            .catch((error) => {
                console.log(error);
            });

    }
    useEffect(() => {
        getAllServices();
    }, [])
    return (
        <div>
            <div className="md:mx-16 mx-6">
                <div className="text-center py-12">
                    <p className="text-sm italic text-[#878353]">Our Services</p>
                    <h1 className="text-3xl md:text-5xl font-serif font-bold mt-2 text-[#202020]">What We’re Offering</h1>
                    <p className="text-gray-600 mt-4 max-w-5xl mx-auto">
                        Discover A World Of Tranquility, Luxury, And Personalized Care As You Embark On A Journey Of Self-Care And Renewal. Visit Us Today And Let Us Transport You To A Realm Of Beauty And Serenity.
                    </p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                    {services.map((service, index) => (
                        <ServiceCard
                            key={index}
                            image={service.image}
                            icon={icon1}
                            name={service.name}
                            description={service.description}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default WhatWereOfferingHome;

function ServiceCard({ image, icon, name, description }) {
    return (
        <div className="bg-white rounded-lg shadow-lg p-2 py-5 relative hover:bg-[#F0EEE1]">
            <div className="flex justify-center">
                <img src={image} alt={name} className="h-[18rem] w-[18rem] rounded-full  object-cover" />
            </div>
            <img src={icon} alt='img' className='w-[5rem] absolute left-[35%] top-[40%] md:left-[50%] md:top-[60%] lg:left-[40%] lg:top-[50%]'/>
            {/* <div className="flex justify-center mt-4">
                <div className="bg-green-700 text-white rounded-full p-2">
                    <img src={icon} alt='img'/>
                  
                </div>
            </div> */}
          <div className='mt-5'>
          <h2 className="text-xl font-bold mt-8 text-center font-serif">{name}</h2>
            <p className="text-gray-600 mt-2 text-center">{description}</p>
            <div className="flex justify-center mt-4">
            <button className="px-4 py-2 mt-auto bg-[#164805] hover:bg-[#3f6327] rounded-md w-32 duration-300 ease-in-out text-white">
            Book Now
          </button>
          </div>
            </div>
        </div>
    );
}