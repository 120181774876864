import React from "react";
import Specialist1 from "../../Assets/home/Specialist1.png";
import Specialist2 from "../../Assets/home/Specialist2.png";
import Specialist3 from "../../Assets/home/Specialist3.png";
import Specialist4 from "../../Assets/home/Specialist4.png";
import { useState, useEffect } from "react";
import axios from "axios";


const OurSpecialists = () => {
  const specialists = [
    {
      name: "Rohan Sharma",
      role: "Massage Specialist",
      image: Specialist1,
      alt: "Portrait of a massage specialist",
    },
    {
      name: "Aditi Mishra",
      role: "Therapist",
      image: Specialist2,
      alt: "Portrait of a therapist",
    },
    {
      name: "Kriti Sharma",
      role: "Therapist",
      image: Specialist3,
      alt: "Portrait of a therapist",
    },
    {
      name: "Aditya Goutam",
      role: "Hair Stylist",
      image: Specialist4,
      alt: "Portrait of a hair stylist",
    },
  ];

  const [team, setTeam] = useState([]);
  const showTeam = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/team/getTeamByBranchId/6745874a9aa6780fb268869a`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Response: ", response.data);
        setTeam(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    showTeam();
  }, []);

  console.log("team : ", team);
  return (
    <div>
      <div className="bg-white px-2 md:px-10">
        <div className="text-center py-6 md:py-12">
          <p className="text-base text-[#878353] italic">Our Team</p>
          <h1 className="title text-3xl md:text-5xl font-bold font-serif text-[#202020]">
            Our Specialists
          </h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 md:gap-8  px-4">
          {team.map((specialist, index) => (
            <div key={index} className="text-center text-[#222222] mb-4">
              <div className="border-custom p-2">
                <img
                  src={specialist.profilePic}
                  alt={specialist.name}
                  className="w-full h-auto"
                />
              </div>
              <h2 className="mt-4 text-xl font-serif font-bold mb-1">
                {specialist.name}
              </h2>
              <p className="text-gray-600">{specialist.role}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurSpecialists;
