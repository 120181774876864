import React from 'react'
import SimpleBanner from '../../Screens/Common/SimpleBanner'
import AboutUs_banner from '../../Assets/about/banarimg.png'
import Map from '../../Components/Contact/Map'
import SubscribeTo from '../../Components/home/SubscribeTo'
import ContactForm from '../../Components/Contact/ContactForm'
const Contact = () => {
  return (
    <div>
      <SimpleBanner
        image={AboutUs_banner}
        name="Contact"
        path="Home >> Contact"
      />
      <ContactForm/>
      <Map/>
      <SubscribeTo/>
    </div>
  )
}

export default Contact
