import React from 'react'

const SimpleBanner = ({ image, name, path }) => {
    return (
        <div
            className='md:h-[350px] h-[250px] flex items-center md:justify-normal justify-center overflow-hidden'
            style={{
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                fontfamily: "Noto Serif Georgian, serif"
            }}
        >
            <div className='text-black text-center w-[70%] mx-auto'>
                <h1 className='md:text-5xl text-3xl font-serif font-bold tracking-wider capitalize'>{name}</h1>
                <p className='tracking-wider mt-2 capitalize'>{path}</p>
            </div>
        </div>
    )
}

export default SimpleBanner;