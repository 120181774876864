import React from "react";
import WhyChooseUsbg from "../../Assets/home/WhyChooseUsbg.png";
import AuthenticTherapy from "../../Assets/home/AuthenticTherapy.png";
import HighQualityProducts from "../../Assets/home/HighQualityProducts.png";
import LuxoriousEnvironment from "../../Assets/home/LuxoriousEnvironment.png";

function WhyChooseUs() {
  return (
    <div
      className="flex justify-end my-8 py-8 px-4 md:px-16 bg-no-repeat bg-right lg:bg-bottom"
      style={{
        backgroundImage: `url(${WhyChooseUsbg})`,
        backgroundSize: "cover",
      }}
    >
      <div className="flex flex-col text-white font-serif items-end lg:pt-16 lg:pb-16">

        <div className="flex flex-col gap-12 md:justify-start items-start lg:w-[50%]">
          <h1 className="text-4xl font-serif font-bold mt-2 text-left">
            Why You Should Choose Us
          </h1>
          <div className="flex gap-4 items-center lg:justify-end">
            <img src={AuthenticTherapy} alt="a Logo" className="h-12 md:h-16" />
            <div className="flex flex-col gap-2 ">
              <h1 className="text-lg md:text-xl font-bold">Authentic Therapies</h1>
              <p className="text-sm md:text-base leading-relaxed">
                We specialize in traditional techniques, including authentic
                Thai massage and a range of holistic treatments that have been
                passed down through generations.
              </p>
            </div>
          </div>

          <div className="flex gap-4 items-center md:justify-start">
            <img src={HighQualityProducts} alt="a Logo" className="h-12 md:h-16" />
            <div className="flex flex-col gap-2">
              <h1 className="text-lg md:text-xl font-bold">High Quality Products</h1>
              <p className="">
                We use only the finest, natural products in all of our treatments, ensuring that your skin and body receive the highest level of care and nourishment.
              </p>
            </div>
          </div>

          <div className="flex gap-4 items-center md:justify-start">
            <img src={LuxoriousEnvironment} alt="a Logo" className="h-12 md:h-16" />
            <div className="flex flex-col gap-2 ">
              <h1 className="text-lg md:text-xl font-bold">Luxorious Environment</h1>
              <p className="text-sm md:text-base leading-relaxed">
                Our spa is designed to be a peaceful retreat from the busy world. From the tranquil ambiance to our calming décor, every detail has been carefully curated to enhance your relaxation experience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyChooseUs;
