import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import smallimage from "../../Assets/home/smallimg.png";
import image from "../../Assets/home/heroimg.png";
const HeroSection = () => {
  const [heroSectionData, setHeroSectionData] = useState();

  const showHeroSection = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/heroSection/getHeroSection/6745874a9aa6780fb268869a`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Response : ", response.data);
        setHeroSectionData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    showHeroSection();
  }, []);
  return (
    <React.Fragment>
      <div
        className="hidden md:block md:py-10 text-center"
        style={{
          backgroundImage: `url(${heroSectionData?.[0]?.bgImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="text-start text-black md:px-16 lg:px-32 py-12 z-10 ">
          <p className="text-lg text-[#878353] tracking-widest">
            WE ARE EXPERT IN SPA & WELLNESS
          </p>
          <h1
            style={{ fontfamily: "Noto Serif Georgian, serif" }}
            className="text-3xl md:text-7xl font-serif mb-4 tracking-wider lg:text-start md:mt-4 lg:font-extrabold md:font-bold italic w-[50%]"
          >
            {/* Beauty Spa &<p className="text-[#164805] mt-4"> Wellness</p> */}
            {heroSectionData?.[0]?.heading}
          </h1>
          <p
            className="text-base text-[#272727] md:text-lg mb-6 tracking-wide text-center md:text-start font-bold md:w-[90%] lg:w-[65%] xl:w-[50%] opacity-70"
            style={{ fontFamily: "Nunito Sans, serif" }}
          >
            {/* Indulge in tranquility at Bangkok Spa. Renew your senses, relax your
            mind, and embrace serenity with our rejuvenating treatments. Your
            journey to bliss begins here. */}
            {heroSectionData?.[0]?.subheading}
          </p>
          <button className="px-4 py-2 mt-auto w-full bg-[#164805] rounded-md md:w-32 hover:bg-[#3f6327] duration-300 ease-in-out text-white ">
            Book Now
          </button>
        </div>
      </div>

      <div
        className="block md:hidden md:py-10 h-fit text-center"
        style={{
          backgroundImage: `url(${heroSectionData?.[0]?.bgImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
        }}
      >
        <div className="text-start text-black p-8 max-w-7xl z-10 md:w-1/2 ">
          <p className="text-base text-center text-[#878353]">
            WE ARE EXPERT IN SPA & WELLNESS
          </p>

          <h1
            style={{ fontfamily: "Noto Serif Georgian, serif" }}
            className="text-3xl font-serif md:text-7xl mb-4 tracking-wider text-center lg:text-start md:mt-0 mt-10 font-extrabold md:font-medium italic"
          >
            {/* Beauty Spa &<p className="text-[#164805] mt-4"> Wellness</p> */}

            {heroSectionData?.[0]?.heading}
          </h1>
          <p className="text-base md:text-lg mb-6 tracking-wide text-center lg:text-start font-bold md:font-normal">
            {/* Discover Your Signature Style With Our Expert Team: Personalized
            Beauty Journeys In A Luxurious Haven - Where Innovation Meets
            Tradition In
            <br />
            The Art Of Beauty. */}

            {heroSectionData?.[0]?.subheading}
          </p>
          <div className="flex justify-center">
            <button className="px-4 py-2 mt-auto bg-[#164805] rounded-md w-32 hover:bg-[#3f6327] duration-300 ease-in-out text-white ">
              Book Now
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HeroSection;
