import React from "react";
import axios from 'axios'
import OurOffers1 from "../../Assets/home/Offersimg1.png";
import OurOffers2 from "../../Assets/home/Offersimg3.png";
import OurOffers3 from "../../Assets/home/Offersimg2.png";
import { useEffect } from "react";
import { useState } from "react";

const OurOffers = () => {
  // const offers = [
  //   {
  //     id: 1,
  //     imageSrc: OurOffers1,
  //     imageAlt: "Navaratri Offer banner with a woman getting her hair done",
  //     title: "Celebrate DURGA PUJA With Soft And Silkier Hair",
  //     description:
  //       "Say Hello To Smooth, Shiny, And Frizz Free Hair With Cysteine Treatment, Exclusive Price For This DURGA PUJA. Benefits: -Keeps Hair Smooth And Shiny.",
  //     aosEffect: "fade-up",
  //   },
  //   {
  //     id: 2,
  //     imageSrc: OurOffers2,
  //     imageAlt:
  //       "Durga Puja Special Offer banner with a woman getting her hair done",
  //     title: "Revitalize Your Hair This DURGA PUJA",
  //     description:
  //       "Experience Luxurious Hair with Our Special Treatment at an Exclusive Price This Festive Season.",
  //     aosEffect: "zoom-in",
  //   },
  //   {
  //     id: 3,
  //     imageSrc: OurOffers3,
  //     imageAlt: "Navaratri Offer banner with a woman getting her hair done",
  //     title: "Say Goodbye to Dull Hair!",
  //     description:
  //       "Our Cysteine Treatment Will Leave Your Hair Shiny and Smooth. Perfect For This Festival!",
  //     aosEffect: "slide-right",
  //   },
  //   {
  //     id: 4,
  //     imageSrc: OurOffers2,
  //     imageAlt:
  //       "Durga Puja Special Offer banner with a woman getting her hair done",
  //     title: "Enhance Your Hair's Beauty This DURGA PUJA",
  //     description:
  //       "Join Us for Exclusive Cysteine Treatment. Shine Bright This Festive Season!",
  //     aosEffect: "fade-left",
  //   },
  // ];

  const [offers, setOffers] = useState([]);
  const getAllOffers = () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/offer/getAllOffersByBranchId/6745874a9aa6780fb268869a`,
      headers: {}
    };

    axios.request(config)
      .then((response) => {
        console.log(response.data);
        setOffers(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    getAllOffers();
  }, [])


  return (
    <div className="md:mx-16 mx-6 mt-10">
      <h1 className="text-3xl md:text-5xl font-serif font-bold mt-2 text-[#1E1E1E] text-center mb-2">
        Our Offers
      </h1>
      <p className="text-center text-gray-600 mb-8">
        We Seek To Build A Lasting Relationship With You Based On Excellence,
        Integrity And Forward-Thinking. FG
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {offers.map((offer) => (
          <div
            key={offer.id}
            className=" p-4 rounded-lg shadow-md"
            data-aos={offer.aosEffect}
          >
            <img
              src={offer.image}
              alt={offer.imageAlt}
              className="w-full rounded-lg mb-4"
            />
            <h2 className="text-xl font-bold mb-2">{offer.name}</h2>
            <p className="text-gray-600">{offer.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurOffers;
