import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import logo from "../../Assets/logo/logo.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { TbMail } from "react-icons/tb";
import { MdOutlineLocalPhone } from "react-icons/md";
import AppointmentModal from "../home/AppointmentModal";
import { FaWhatsapp } from "react-icons/fa";
import axios from "axios";
import { useEffect } from "react";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get current location
  const [isOpen, setIsOpen] = useState(false);
  const [menu, setMenu] = useState([]);

  const fetchMenuData = async () => {
    // setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/menu/getMenu/6745874a9aa6780fb268869a`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Response of Fetching menu: ", response.data);
        setMenu(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchMenuData();
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const getLinkClass = (path) => {
    return location.pathname === path
      ? "text-base font-semibold text-[#164805] cursor-pointer tracking-wide"
      : "text-base font-semibold hover:text-[#164805] cursor-pointer tracking-wide";
  };

  const [open, setOpen] = useState(false);

  const handleAddOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };
  return (
    <>
      <nav className="hidden md:block bg-[#164805] text-white px-6 md:px-16 py-2">
        <div className="container mx-auto flex items-center justify-between relative">
          {/* 1st Section - Phone and Email */}
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <TbMail size={23} />
              <span className="text-sm tracking-wide">
                bangkokspa2017@gmail.com
              </span>
            </div>
            <div className="flex items-center space-x-1">
              <MdOutlineLocalPhone size={22} />
              <span className="text-sm tracking-wide">+91-8114882165</span>
            </div>
          </div>
          {/* 3rd Section - Social Media Icons */}
          <div className="flex space-x-4">
            <div>
              <a
                href="https://www.facebook.com/kairaspabbsr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF />
              </a>
            </div>
            <div>
              <a
                href="https://www.instagram.com/kaira_spa_21"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
            </div>
            <div>
              <a
                href="https://wa.me/9861688844?text=Welcome to Kaya Spa. How may we assist you?%20there%20I%20need%20help!"
                target="_blank"
              >
                <FaWhatsapp />
              </a>
            </div>
          </div>
        </div>
      </nav>
      {/* Navbar */}
      <nav className="bg-whites px-6 md:px-16 py-2 shadow-2xl">
        <div className="container mx-auto flex items-center justify-between">
          {/* 1st Section - Logo */}
          <div>
            <img src={logo} alt="baisaly-logo" className="w-auto h-14" />
          </div>

          {/* Middle Section - Page Links (hidden on small screens) */}
          <div className="hidden md:flex space-x-8">
            <div onClick={() => navigate("/")} className={getLinkClass("/")}>
              Home
            </div>
            <div
              onClick={() => navigate("/about")}
              className={getLinkClass("/about")}
            >
              About
            </div>
            <div
              onClick={() => navigate("/services")}
              className={getLinkClass("/services")}
            >
              Services
            </div>
            <div
              onClick={() => navigate("/offers")}
              className={getLinkClass("/offers")}
            >
              Offers
            </div>
            <div
              onClick={() => navigate("/contact")}
              className={getLinkClass("/contact")}
            >
              Contact
            </div>
            <div className="text-base font-semibold hover:text-[#164805] cursor-pointer tracking-wide">
              <a href={menu?.data?.[0]?.menuURL}>Menu</a>
            </div>
          </div>

          {/* 3rd Section - Login Button */}
          <div className="hidden md:block" onClick={handleAddOpen}>
            <button className="px-4 py-2 mt-auto bg-[#164805] hover:bg-[#3f6327] rounded-md w-32 duration-300 ease-in-out text-white">
              Appointment
            </button>
          </div>
          {open && <AppointmentModal onClose={handleModalClose} />}
          {/* Hamburger Menu for Small Screens */}
          <div className="md:hidden">
            <FaBars
              onClick={toggleSidebar}
              size={24}
              className="cursor-pointer"
            />
          </div>
        </div>
      </nav>

      {/* Sidebar for Small Screens */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 z-50">
          <div className="fixed top-0 left-0 w-64 h-full bg-white shadow-md">
            <div className="p-4">
              <button
                className="text-black text-lg font-bold mb-8 float-right"
                onClick={toggleSidebar}
              >
                <IoClose size={25} className="text-[#164805]" />
              </button>

              <ul className="space-y-4 text-lg">
                <li
                  onClick={() => {
                    navigate("/");
                    toggleSidebar();
                  }}
                  className={getLinkClass("/")}
                >
                  Home
                </li>
                <li
                  onClick={() => {
                    navigate("/about");
                    toggleSidebar();
                  }}
                  className={getLinkClass("/about")}
                >
                  About
                </li>
                <li
                  onClick={() => {
                    navigate("/services");
                    toggleSidebar();
                  }}
                  className={getLinkClass("/services")}
                >
                  Services
                </li>
                <li
                  onClick={() => {
                    navigate("/offers");
                    toggleSidebar();
                  }}
                  className={getLinkClass("/offers")}
                >
                  Offers
                </li>
                <li
                  onClick={() => {
                    navigate("/contact");
                    toggleSidebar();
                  }}
                  className={getLinkClass("/contact")}
                >
                  Contact
                </li>

                <li>
                  <div className="text-base font-semibold hover:text-[#164805] cursor-pointer tracking-wide">
                    <a href={menu?.data?.[0]?.menuURL}>Menu</a>
                  </div>
                </li>
              </ul>
              <button className="tracking-wide mt-8 bg-[#164805] text-white font-bold py-2 px-4 rounded">
                Log In
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
