import React from "react";
import logo from "../../Assets/Common/serviceLogo.png";
import leftDesign from "../../Assets/Common/leftDesign.png";
import rightDesign from "../../Assets/Common/rightDesign.png";
import checkLogo from "../../Assets/Common/checkLogo.png";
import axios from 'axios'
import { useState } from "react";
import { useEffect } from "react";
const OurPricing = () => {
  const [plans,setPlans] = useState([])
  const getALlPlans = ()=>{
      let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_KEY}/plans/getAllPlansByBranchId/6745874a9aa6780fb268869a`,
          headers: { }
        };
        axios.request(config)
        .then((response) => {
          console.log(response.data);
          
          setPlans(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
  }
  useEffect(()=>{
      getALlPlans();
  },[])
  return (
    <div className="bg-white items-center py-2 mx-2">
      <h1 className="text-3xl md:text-5xl font-serif font-semibold text-[#202020] text-center my-8">
        Our Pricing
      </h1>
      <div className="grid grid-cols-1 px-6 md:px-16 gap-4 md:grid-cols-2 lg:grid-cols-3 items-center justify-center space-y-6 mt-10 md:space-y-0">
        {plans.map((plan) => (
          <div
            key={plan.id}
            className="p-6 rounded-lg shadow-lg border text-center border-[#164805] relative"
            data-aos="fade-up"
          >
            <img
              src={leftDesign}
              alt="design"
              className="absolute top-0 left-0"
            />
            <div className="flex justify-center mb-4">
              <img src={logo} alt="Service logo" />
            </div>
            <h2 className="text-2xl font-bold font-serif text-[#164805]">
             {plan.type} Plan
            </h2>
            <div className="text-3xl font-bold text-[#434242] mb-2 text-center">
            <span>₹</span>{plan.price} /-
              <span className="text-lg font-normal">{plan.duration}</span>
            </div>
            <hr className="border-[#164805] my-4" />
            <ul className="md:ml-8 xl:ml-20 space-y-5 mb-6 text-[#595959] text-start">
              {plan.keyPoints.map((feature, index) => (
                <li key={index} className="flex items-center mb-2">
                  <img src={checkLogo} alt="check icon" className="mr-2" />
                  {feature}
                </li>
              ))}
            </ul>
            <button className="px-4 py-2 mt-4 bg-[#164805] hover:bg-[#3f6327] rounded-md w-32 duration-300 ease-in-out text-white">
              Book Now
            </button>
            <img
              src={rightDesign}
              alt="design"
              className="absolute bottom-0 right-0"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurPricing;
