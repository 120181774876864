import React from 'react';

const SubscribeTo = () => {
    return (
        <React.Fragment>
            <div className="text-center py-8">
                <h1
                    className="text-3xl lg:text-5xl font-semibold text-center mb-4 font-serif text-[#202020]"
                    data-aos="flip-left"
                >
                    Subscribe To Our Newsletter
                </h1>
                <p
                    className="text-gray-600 text-base text-center mx-auto max-w-[90%] sm:max-w-[80%] md:max-w-[65%]  md:text-md lg:text-xl"
                    data-aos="flip-up"
                >
                  Stay updated with the latest news, insights, and exclusive offers by subscribing to our newsletter. Join our community and be the first to know about upcoming events, product updates, and more!
                </p>
                <div className="mt-8 flex justify-center" data-aos="flip-down">
                    <div className="relative w-full max-w-[90%] sm:max-w-[80%] md:max-w-[70%] lg:max-w-[60%] xl:max-w-[50%] 2xl:max-w-[40%]">
                        <input
                            type="email"
                            placeholder="Email Address"
                            className="w-full p-4 pr-32 text-gray-700 bg-white border border-gray-300 rounded-md shadow-md focus:outline-none focus:ring-2"
                        />
                         <button
                        className="absolute top-1 right-3 tracking-wide bg-[#164805] text-white font-bold py-3 px-4 rounded">
                        Subscribe →
                    </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SubscribeTo;
