import React from 'react'
import img1 from '../../Assets/home/Services1.png'
import img2 from '../../Assets/home/Services2.png'
import img3 from '../../Assets/home/Services3.png'
import icon1 from '../../Assets/home/icons.png'
import icon2 from '../../Assets/home/icons1.png'
import icon3 from '../../Assets/home/icons3.png'
const WhatWereOfferingServices = () => {

    const services = [
        {
            image: img1,
            icon: icon1,
            title: "FACIAL CARE",
            description: "Experience radiant skin with our rejuvenating facial care treatments. Relax, refresh, and glow as our experts pamper your skin, leaving you feeling revitalized and beautifully renewed."
        },
        {
            image: img2,
            icon: icon2,
            title: "BODY SCRUBS",
            description: "Reveal soft, glowing skin with our luxurious body scrub treatments. Gently exfoliate and nourish as our expert therapists polish away dullness, leaving you feeling refreshed, smooth, and deeply revitalized."
        },
        {
            image: img3,
            icon: icon3,
            title: "AROMA OIL",
            description: "Embark On A Sensory Journey With Our Aromatic Oil Massages. Indulge In The Perfect Blend Of Soothing Scents And Expert Touch, As Our Therapists Transport You To A State Of Pure Tranquility."
        },
        {
            image: img1,
            icon: icon1,
            title: "SWEDISH MASSAGE",
            description: "Experience radiant skin with our rejuvenating facial care treatments. Relax, refresh, and glow as our experts pamper your skin, leaving you feeling revitalized and beautifully renewed."
        },
        {
            image: img2,
            icon: icon2,
            title: "HOT STONE MASSAGE",
            description: "Reveal soft, glowing skin with our luxurious body scrub treatments. Gently exfoliate and nourish as our expert therapists polish away dullness, leaving you feeling refreshed, smooth, and deeply revitalized."
        },
        {
            image: img3,
            icon: icon3,
            title: "ANTI-AGING FACIAL",
            description: "Embark On A Sensory Journey With Our Aromatic Oil Massages. Indulge In The Perfect Blend Of Soothing Scents And Expert Touch, As Our Therapists Transport You To A State Of Pure Tranquility."
        },
        {
            image: img1,
            icon: icon1,
            title: "CUPPING THERAPY",
            description: "Experience radiant skin with our rejuvenating facial care treatments. Relax, refresh, and glow as our experts pamper your skin, leaving you feeling revitalized and beautifully renewed."
        },
        {
            image: img2,
            icon: icon2,
            title: "HYDRATING FACIAL",
            description: "Reveal soft, glowing skin with our luxurious body scrub treatments. Gently exfoliate and nourish as our expert therapists polish away dullness, leaving you feeling refreshed, smooth, and deeply revitalized."
        },
        {
            image: img3,
            icon: icon3,
            title: "AROMA OIL",
            description: "Embark On A Sensory Journey With Our Aromatic Oil Massages. Indulge In The Perfect Blend Of Soothing Scents And Expert Touch, As Our Therapists Transport You To A State Of Pure Tranquility."
        },
        
    ];

    return (
        <div>
            <div className="md:mx-16 mx-6">
                <div className="text-center py-12">
                    <p className="text-base italic text-[#878353]">Our Services</p>
                    <h1 className="text-3xl md:text-5xl font-serif font-bold mt-2 text-[#202020]">What We’re Offering</h1>
                    <p className="text-gray-600 mt-4 max-w-5xl mx-auto">
                        Discover A World Of Tranquility, Luxury, And Personalized Care As You Embark On A Journey Of Self-Care And Renewal. Visit Us Today And Let Us Transport You To A Realm Of Beauty And Serenity.
                    </p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                    {services.map((service, index) => (
                        <ServiceCard
                            key={index}
                            image={service.image}
                            icon={service.icon}
                            title={service.title}
                            description={service.description}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default WhatWereOfferingServices;

function ServiceCard({ image, icon, title, description }) {
    return (
        <div className="bg-white rounded-lg shadow-lg p-2 py-5 relative hover:bg-[#F0EEE1]">
            <div className="flex justify-center">
                <img src={image} alt={title} className="rounded-full  object-cover" />
            </div>
            <img src={icon} alt='img' className='w-[5rem] absolute left-[35%] top-[40%] md:left-[30%] md:top-[30%] lg:left-[40%] lg:top-[40%]'/>
           
          <div className='mt-16'>
          <h2 className="text-xl font-bold mt-4 text-center font-serif">{title}</h2>
            <p className="text-gray-600 mt-2 text-center">{description}</p>
            <div className="flex justify-center mt-4">
            <button className="px-4 py-2 mt-auto bg-[#164805] hover:bg-[#3f6327] rounded-md w-32 duration-300 ease-in-out text-white">
            Book Now
          </button>
          </div>
            </div>
        </div>
    );
}