import React  from "react";
import { useState, useEffect } from "react";
import axios from "axios";

function VisualTour() {

  //State to store all the gallery photos of a branch
  const [allGallery, setAllGallery] = useState([]);

  //Function to fetch all the gallery photos of a branch
  const showAllgalleryData = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/gallery/getGallery/6745874a9aa6780fb268869a`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Response---->", response.data);
        setAllGallery(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log("Gallery----->", allGallery);

  useEffect(() => {
    showAllgalleryData();
  }, []);

  return (
    <div className="p-8 md:p-16 md:pt-12 flex flex-col">
      <h5 className="lato-thin-italic italic text-[#878353] text-center md:mb-2">
        Our Gallery
      </h5>
      <h1 className="text-3xl md:text-5xl text-center font-serif font-semibold text-[#202020] mb-4 md:mb-8">
        Visual Tour
      </h1>

      <div className="md:hidden flex justify-center items-center ">
        <img
          src={allGallery[0]?.gallery}
          alt="img"
          className="h-auto w-full object-contain"
          data-aos="fade-up"
        />
      </div>
      <div className="hidden md:grid grid-cols-2 md:w-[30%] mx-auto md:pb-8 pb-4 gap-4 md:gap-8">
        <img
          src={allGallery[0]?.gallery}
          alt="img"
          className="mt-auto rounded-tl-[30%]"
          data-aos="fade-right"
        />
        <img
          src={allGallery[1]?.gallery}
          alt="img"
          className="mt-auto"
          data-aos="fade-left"
        />
      </div>
      <div className="hidden md:grid grid-cols-3 md:w-[100%] mx-auto md:gap-8 gap-4">
        <div className="md:space-y-8">
          <img
            src={allGallery[3]?.gallery}
            alt="img"
            className="float-right"
            data-aos="zoom-in"
          />
          <img
            src={allGallery[4]?.gallery}
            alt="img"
            className="float-right rounded-bl-[30%] "
            data-aos="zoom-in"
          />
        </div>
        <div>
          <img
            src={allGallery[5]?.gallery}
            alt="img"
            className="md:h-full md:w-full object-cover "
            data-aos="flip-left"
          />
        </div>
        <div className="space-y-8">
          <img
            src={allGallery[6]?.gallery}
            alt="img"
            data-aos="fade-up"
            className="mt-auto rounded-tr-[30%] "
          />
          <img src={allGallery[7]?.gallery} alt="img" data-aos="fade-up" />
        </div>
      </div>
      <div className="hidden md:grid grid-cols-2 md:w-[30%] mx-auto pt-8 gap-8">
        <img src={allGallery[8]?.gallery} alt="img" data-aos="fade-right" className="" />
        <img
          src={allGallery[8]?.gallery}
          alt="img"
          data-aos="fade-left"
          className="mt-auto rounded-br-[30%] "
        />
      </div>
    </div>
  );
}

export default VisualTour;
