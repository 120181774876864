import React from 'react'
import HeroSection from '../../Components/home/HeroSection'
import OurPricing from '../../Components/Common/OurPricing'
import WhatTheySay from '../../Components/Common/WhatTheySay'
import GetToknow from '../../Components/abouts/GetToknow'
import WhatWereOfferingHome from '../../Components/home/WhatWereOfferingHome'
import VisualTour from '../../Components/home/VisualTour'
import SubScribeTo from '../../Components/home/SubscribeTo'
import WhyChooseUs from '../../Components/home/WhyChooseUs'
import ContactForm from '../../Components/Contact/ContactForm'
import OurSpecialists from '../../Components/home/OurSpecialists'
const Home = () => {
  return (
    <div>
      <HeroSection/>
      <GetToknow />
      <WhatWereOfferingHome /> 
      <WhyChooseUs />
      <OurPricing />
      <VisualTour />
      <WhatTheySay />
      <OurSpecialists />
      <ContactForm />
      <SubScribeTo />
    </div>
  )
}

export default Home
